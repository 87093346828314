import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PreviewCompatibleImage from "../components/previewCompatibleImage"

const SuccessWrapper = styled.section`
  position: relative;

  .success-container {
    background-color: rgba(225, 225, 225, 0.9);
    padding: 2rem 4rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      margin-bottom: 2rem;
      font-family: "Lato", sans-serif;
      text-align: center;
    }

    .content {
      text-align: center;
      max-width: 600px;
      font-size: 1.2rem;
    }
  }
`

export const SuccessPageTemplate = ({ title, content, button, image }) => (
  <SuccessWrapper>
    <PreviewCompatibleImage
      imageInfo={image}
      style={{ width: "100vw", height: "calc(100vh - 209px)" }}
    />
    <article>
      <div className="success-container">
        <header>
          <h1>{title}</h1>
        </header>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <Link style={{ textAlign: "center" }} className="to-item-link" to="/">
          {button}
        </Link>
      </div>
    </article>
  </SuccessWrapper>
)

SuccessPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  button: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const SuccessPage = ({ data }) => {
  const { markdownRemark: success } = data
  return (
    <Layout>
      <SEO
        title={success.frontmatter.seo.browserTitle}
        description={success.frontmatter.seo.description}
      />
      <SuccessPageTemplate
        title={success.frontmatter.title}
        content={success.html}
        button={success.frontmatter.button}
        image={success.frontmatter.successImage}
      />
    </Layout>
  )
}

SuccessPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const pageQuery = graphql`
  query SuccessPageData {
    markdownRemark(frontmatter: { templateKey: { eq: "success-page" } }) {
      html
      frontmatter {
        title
        button
        successImage {
          image {
            childImageSharp {
              fluid(maxWidth: 1680) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
        }
        seo {
          browserTitle
          description
        }
      }
    }
  }
`

export default SuccessPage
